import { useState, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import cookies from 'js-cookie';

import gqlService from '@sellermodules/order/services/graphql';
import gqlStore from '@sellermodules/storesetting/services/graphql';
import themeService from '@modules/theme/services/graphql';

import Layout from '@layout';
import BackdropLoad from '@helper_backdropload';
import formatDate, { getMinDate } from '@helper_date';

const ContentWrapper = (props) => {
    const {
        Content, t, orderStatus, rangeDate, allowedMpPrintLabel,
    } = props;
    const router = useRouter();
    const { status } = router.query;

    const [rangeDateState, setRangeDateState] = useState([formatDate(getMinDate(rangeDate), 'YYYY-MM-DD 00:00:00'),
        formatDate(new Date(), 'YYYY-MM-DD 23:59:59')]);
    const [doRefetch, setDoRefetch] = useState(false);
    const [printLabelState, setPrintLabelState] = useState({});
    const [checked, setChecked] = useState([]);
    const [selectedPrintLabel, setSelectedPrintLabel] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(null);
    const [openProgress, setOpenProgress] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [activityState, setActivityState] = useState({});
    const [activityCalled, setActivityCalled] = useState(false);

    const [getSellerOrders, { data: dataOrders, loading: loadingOrders }] = gqlService.getSellerOrders();
    const [getSellerOrderSyncStatusList, { data: failedSyncData, loading: failedSyncLoading }] = gqlService.getSellerOrderSyncStatusList();
    const [printSellerChannelOrderLabel] = gqlService.printSellerChannelOrderLabel();
    const [retryPrintSellerChannelOrderLabel] = gqlService.retryPrintSellerChannelOrderLabel();
    const [retrySellerOrderSync] = gqlService.retrySellerOrderSync();
    const [getActivity] = gqlService.getActivity({
        variables: {
            code: `download_seller_order_report_${cookies.getJSON('cdt')?.customer_company_code}`,
        },
        onCompleted: (res) => {
            window.backdropLoader(false);
            setOpenExport(true);
            setActivityState({ ...res.getActivity });
            if (res.getActivity.run_status === 'running') {
                setActivityCalled(true);
                setTimeout(() => {
                    getActivity();
                }, 100);
            }
        },
        onError: () => {
            getActivity();
        },
    });

    const { data: dataMeta, refetch: refetchMeta } = gqlService.getSellerOrderMeta({
        filter: {
            order_date: { from: rangeDateState[0], to: rangeDateState[1] },
        },
    });
    const { data: dataCountSyncFailed, refetch: refetchCountSyncFailed } = gqlService.getSellerOrderSyncStatusListCount({
        filter: {
            order_date: { from: rangeDateState[0], to: rangeDateState[1] },
            sync_status: { eq: 'failed' },
            table_source: { eq: 'sales_shipment' },
        },
    });

    const dataOrderMeta = dataMeta?.getSellerOrderMeta?.order || {};

    const findStatusLabel = (stat) => orderStatus.find(({ value }) => value === stat)?.label || '';

    const dataTabs = useMemo(() => [
        { label: t('sellerorder:All_Orders'), value: 'all', count: dataOrderMeta?.all_orders?.value?.toString() },
        { label: findStatusLabel('unconfirmed'), value: 'unconfirmed', count: dataOrderMeta?.new_order?.value?.toString() },
        { label: findStatusLabel('ready_for_ship'), value: 'ready_for_ship', count: dataOrderMeta?.packed?.value?.toString() },
        { label: findStatusLabel('order_shipped'), value: 'order_shipped', count: dataOrderMeta?.shipped?.value?.toString() },
        { label: findStatusLabel('order_delivered'), value: 'order_delivered', count: dataOrderMeta?.complete?.value?.toString() },
        { label: findStatusLabel('canceled'), value: 'canceled', count: dataOrderMeta?.canceled?.value?.toString() },
        {
            label: t('sellerorder:Sync_Failed'),
            value: 'sync_failed',
            count: dataCountSyncFailed?.getSellerOrderSyncStatusList
                ?.total_count?.toString(),
        },
    ], [dataOrderMeta, dataCountSyncFailed]);

    const onReloadAllOrderCount = () => {
        refetchMeta();
        refetchCountSyncFailed();
    };

    const getProps = () => {
        let getRows = getSellerOrders;
        let data = dataOrders;
        let loading = loadingOrders;
        switch (status) {
        case 'sync_failed':
            getRows = getSellerOrderSyncStatusList;
            data = failedSyncData;
            loading = failedSyncLoading;
            break;
        default:
            break;
        }
        return { getRows, data, loading };
    };

    const handleRetry = (params) => {
        const input = params.map(({ entity_id, channel_code, table_source }) => ({
            channel_code, table_source, id: String(entity_id),
        }));
        window.backdropLoader(true);
        retrySellerOrderSync({ variables: { input } }).then((res) => {
            if (res.data.retrySellerOrderSync > 0) {
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    text: t('sellerorder:count_orders_have_been_successfully_resync', { count: res.data.retrySellerOrderSync }),
                    variant: 'success',
                });
                setDoRefetch(true);
            } else {
                throw new Error(t('sellerorder:Something_went_wrong_when_try_to_resync_the_orders'));
            }
        }).catch((e) => {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                text: e.message,
                variant: 'error',
            });
        });
    };

    const handlePrintLabel = async () => {
        setOpenConfirmation(false);
        window.backdropLoader(true);
        await printSellerChannelOrderLabel({ variables: { ids: [selectedPrintLabel] } })
            .then((res) => {
                window.backdropLoader(false);
                const resUrl = res?.data?.printSellerChannelOrderLabel?.[0];
                if (resUrl) {
                    window.open(resUrl);
                    setDoRefetch(true);
                } else {
                    window.toastMessage({
                        open: true,
                        text: t('sellerorder:Shipping_label_not_found_or_order_is_more_than_14_days_old'),
                        variant: 'error',
                    });
                }
            })
            .catch((e) => {
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    text: e.message,
                    variant: 'error',
                });
            });
    };

    const handleRetryPrintLabel = async () => {
        setOpenConfirmation(false);
        window.backdropLoader(true);
        await retryPrintSellerChannelOrderLabel({ variables: { id: selectedPrintLabel } })
            .then((res) => {
                window.backdropLoader(false);
                const resUrl = res?.data?.retryPrintSellerChannelOrderLabel?.[0];
                if (resUrl) {
                    if (resUrl?.startsWith('http')) {
                        window.open(resUrl);
                    } else {
                        // Membersihkan HTML dari karakter escape "\"
                        const cleanHtml = resUrl?.replace(/\\/g, '');
                        const newWindow = window.open();
                        if (newWindow) {
                            // Menulis konten HTML ke tab baru
                            newWindow.document.open();
                            newWindow.document.write(cleanHtml);
                            newWindow.document.close();
                        }
                    }
                    setDoRefetch(true);
                } else {
                    window.toastMessage({
                        open: true,
                        text: t('sellerorder:Shipping_label_not_found_or_order_is_more_than_14_days_old'),
                        variant: 'error',
                    });
                }
            })
            .catch((e) => {
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    text: e.message,
                    variant: 'error',
                });
            });
    };

    const handlePrintMultipleLabel = () => {
        setOpenConfirmation(false);
        setPrintLabelState({});
        const printOmsIds = [];
        const print = new Promise((resolve) => {
            // eslint-disable-next-line no-unused-expressions
            checked?.forEach(async (item, index) => {
                if (allowedMpPrintLabel.some((v) => (item?.channel_code || item)?.indexOf(v) >= 0)) {
                    setOpenProgress(true);
                    setPrintLabelState((prev) => ({
                        ...prev,
                        [item.order_number]: {
                            id: item.id,
                            status: 'loading',
                        },
                    }));
                    const mutationName = item.print_pack_counter ? 'retryPrintSellerChannelOrderLabel' : 'printSellerChannelOrderLabel';
                    const useMutation = item.print_pack_counter ? retryPrintSellerChannelOrderLabel : printSellerChannelOrderLabel;

                    await useMutation({ variables: item.print_pack_counter ? { id: item.id } : { ids: [item.id] } })
                        .then((res) => {
                            const resUrl = res?.data?.[mutationName]?.[0];
                            if (resUrl) {
                                if (item.print_pack_counter) {
                                    if (resUrl?.startsWith('http')) {
                                        window.open(resUrl);
                                    } else {
                                        const cleanHtml = resUrl?.replace(/\\/g, '');
                                        const newWindow = window.open();
                                        if (newWindow) {
                                            newWindow.document.open();
                                            newWindow.document.write(cleanHtml);
                                            newWindow.document.close();
                                        }
                                    }
                                } else {
                                    window.open(resUrl);
                                }
                                setPrintLabelState((prev) => ({
                                    ...prev,
                                    [item.order_number]: {
                                        id: item.id,
                                        status: 'finished',
                                    },
                                }));
                            } else {
                                setPrintLabelState((prev) => ({
                                    ...prev,
                                    [item.order_number]: {
                                        id: item.id,
                                        status: 'error',
                                    },
                                }));
                            }
                        })
                        .catch((e) => {
                            setPrintLabelState((prev) => ({
                                ...prev,
                                [item.order_number]: {
                                    id: item.id,
                                    status: 'error',
                                },
                            }));
                            window.backdropLoader(false);
                            window.toastMessage({
                                open: true,
                                text: e.message,
                                variant: 'error',
                            });
                        });
                } else {
                    setPrintLabelState((prev) => ({
                        ...prev,
                        [item.order_number]: {
                            id: item.id,
                            status: 'finished',
                        },
                    }));
                    printOmsIds.push(item.id);
                }
                if (index === checked.length - 1) resolve();
            });
        });
        print.then(() => {
            if (printOmsIds.length) {
                window.open(`/seller/order/orderlist/printlabel/${printOmsIds.join('/')}`);
            }
            setDoRefetch(true);
        });
    };

    const onOpenExport = async () => {
        window.backdropLoader(true);
        getActivity();
    };

    const contentProps = {
        ...props,
        getRows: getProps().getRows,
        data: getProps().data,
        loading: getProps().loading,
        dataTabs,
        onReloadAllOrderCount,
        findStatusLabel,
        setRangeDateState,
        status,
        handleRetry,
        doRefetch,
        setDoRefetch,
        printSellerChannelOrderLabel,
        printLabelState,
        setChecked,
        handlePrintLabel,
        handleRetryPrintLabel,
        handlePrintMultipleLabel,
        openConfirmation,
        setOpenConfirmation,
        selectedPrintLabel,
        setSelectedPrintLabel,
        openProgress,
        setOpenProgress,
        openExport,
        setOpenExport,
        getActivity,
        onOpenExport,
        activityState,
        activityCalled,
        setActivityCalled,
    };

    return (
        <Content {...contentProps} />
    );
};

const Core = (props) => {
    const { t, storeConfig } = props;

    const router = useRouter();
    const pageConfig = {
        title: t('sellerorder:Order_List'),
        customBreadcrumb: [
            { url: '', label: 'Order' },
            { url: router.asPath, label: t('sellerorder:Order_List') },
        ],
    };
    const { loading: aclCheckLoading, data: aclCheckData } = themeService.isAccessAllowed({
        acl_code: 'seller_order_list',
    });
    const { loading: aclEditLoading, data: aclEditData } = themeService.isAccessAllowed({
        acl_code: 'seller_order_edit',
    });
    const { loading: loadShipment, data: dataShipment } = gqlStore.getSellerShippingMethods();
    const { loading: loadLocation, data: dataLocation } = gqlService.getSellerStoreOptions();
    const { loading: loadStatus, data: dataStatus } = gqlService.getSellerOrderStatus();
    const { data: dataStores, loading: loadStores } = gqlService.csStores({
        variables: {
            page: 1,
            first: 1000,
            sort: [{ column: 'ID', order: 'ASC' }],
            hasChannel: {
                column: 'CHANNEL_TYPE',
                operator: 'NEQ',
                value: 'Chat',
            },
        },
    });
    const { loading: loadingPlatform, data: dataPlatform } = gqlService.getMarketplacePlatforms({
        pageSize: null,
        currentPage: 1,
    });

    useEffect(() => {
        if (router.route === '/') {
            router.replace('/seller/order/orderlist', undefined, { shallow: true });
        }
    }, []);

    useEffect(() => {
        BackdropLoad(aclCheckLoading || aclEditLoading || loadShipment || loadLocation || loadStatus || loadStores || loadingPlatform);
    }, [aclCheckLoading, aclEditLoading, loadShipment, loadLocation, loadStatus, loadStores, loadingPlatform]);

    if (aclCheckLoading || aclEditLoading || loadShipment || loadLocation || loadStatus || loadStores || loadingPlatform) {
        return <Layout pageConfig={pageConfig} seller />;
    }

    if (!aclCheckData?.isAccessAllowed) {
        router.push('/seller/unauthorized');
        return <Layout pageConfig={pageConfig} seller />;
    }

    const contentProps = {
        ...props,
        dataProvider: dataShipment?.getSellerShippingMethods || [],
        dataLocation: dataLocation?.getSellerStoreOptions || [],
        rangeDate: storeConfig?.default_order_grid_period_filter || 3,
        canFilterExpedition: storeConfig?.swiftoms_vendor_enable_store_shipping === '1',
        orderStatus: dataStatus?.getSellerOrderStatus || [],
        dataStores: dataStores?.csStores?.data || [],
        isMultichannel: storeConfig?.swiftoms_vendor_enable_multi_channel === '1',
        allowedMpPrintLabel: storeConfig?.swiftoms_enable_channel_shipping_label_for?.split(','),
        aclEdit: aclEditData.isAccessAllowed,
        dataPlatform: dataPlatform?.getMarketplacePlatforms?.items || [],
    };

    return (
        <Layout pageConfig={pageConfig} seller>
            <ContentWrapper {...contentProps} />
        </Layout>
    );
};

export default Core;
