import Link from 'next/link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@common_button';
import { makeStyles } from '@material-ui/core/styles';
import {
    PRIMARY_DARK, PRIMARY, GREEN_STATUS_TABLE_BORDER, GREEN_STATUS_TABLE, GRAY_LIGHT,
} from '@theme_color';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
    title: {
        '& .MuiTypography-root': {
            color: PRIMARY_DARK,
            fontSize: 20,
            fontWeight: 600,
        },
        '&.MuiDialogTitle-root': {
            paddingBottom: 0,
        },
    },
    text: {
        '&.MuiDialogContentText-root': {
            color: PRIMARY_DARK,
            fontSize: 14,
        },
    },
    btn: {
        '&.MuiDialogActions-root': {
            padding: 20,
            justifyContent: 'center',
        },
    },
    link: {
        fontWeight: 'bold',
        color: PRIMARY,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    linearDiv: {
        color: PRIMARY_DARK,
        alignItems: 'center',
        '& .progress': {
            width: '100%',
            textAlign: 'right',
            fontSize: 14,
            fontWeight: 'bold',
        },
        '& .percent': {
            fontSize: 12,
            marginLeft: 5,
        },
    },
    progressRoot: {
        marginTop: 6,
        marginBottom: 6,
        height: 15,
        borderRadius: 8,
    },
    progressColorActive: {
        backgroundColor: GREEN_STATUS_TABLE_BORDER,
    },
    progressColor: {
        backgroundColor: GREEN_STATUS_TABLE,
    },
    btnDisabled: {
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: GRAY_LIGHT,
            color: 'white',
        },
    },
}));

const ProgressDialog = (props) => {
    const {
        openProgress = false,
        setOpenProgress,
        t,
        printLabelState,
    } = props;
    const classes = useStyles();

    const onClose = () => {
        setOpenProgress(null);
    };

    return (
        <Dialog
            open={openProgress}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle className={classes.title}>{t('sellerorder:Print_Label')}</DialogTitle>
            <DialogContent>
                <div className={classes.linearDiv}>
                    <div className="progress">
                        {Object.keys(printLabelState).filter((key) => ['finished', 'error'].includes(printLabelState[key].status)).length}
                        {' / '}
                        {Object.keys(printLabelState).length}
                        <span className="percent">
                            {`(${Math.round((Object.keys(printLabelState).filter((key) => ['finished', 'error']
                                .includes(printLabelState[key].status)).length
                                / Object.keys(printLabelState).length) * 100)}%)`}
                        </span>
                    </div>
                    <LinearProgress
                        classes={{
                            root: classes.progressRoot,
                            barColorPrimary: classes.progressColorActive,
                            colorPrimary: classes.progressColor,
                        }}
                        variant={Object.keys(printLabelState).filter((key) => ['finished', 'error'].includes(printLabelState[key].status)).length
                            ? 'determinate' : 'indeterminate'}
                        value={Math.round((Object.keys(printLabelState).filter((key) => ['finished', 'error']
                            .includes(printLabelState[key].status)).length
                                / Object.keys(printLabelState).length) * 100)}
                    />
                </div>
            </DialogContent>
            {!!Object.keys(printLabelState).filter((key) => !['loading', 'finished'].includes(printLabelState[key].status))?.length
                && (
                    <DialogContent>
                        <DialogContentText className={classes.text}>
                            {t('sellerorder:Failed_to_print_shipping_labels_for_the_following_orders')}
                            {' :'}
                            <ul>

                                {Object.keys(printLabelState)
                                    .filter((key) => !['loading', 'finished'].includes(printLabelState[key].status))
                                    .map((key) => (
                                        <li>
                                            <Link href={`/seller/order/orderlist/detail/${printLabelState[key].id}`} passHref>
                                                <a target="_blank" className={classes.link}>
                                                    {key}
                                                </a>
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                )}
            <DialogActions className={classes.btn}>
                <Button
                    className={Object.keys(printLabelState).some((key) => printLabelState[key].status === 'loading') && classes.btnDisabled}
                    onClick={onClose}
                    color="primary"
                    disabled={Object.keys(printLabelState).some((key) => printLabelState[key].status === 'loading')}
                >
                    {Object.keys(printLabelState).some((key) => printLabelState[key].status === 'loading')
                        ? t('sellerorder:Loading')
                        : t('sellerorder:OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProgressDialog;
