import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    PRIMARY, PRIMARY_DARK, GRAY_LIGHT,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    expandContainer: {
        background: 'white',
        '&.expand': {
            padding: '20px 10px',
        },
    },
    expandGrid: {
        display: 'grid',
        gridTemplateColumns: '80px auto',
        alignItems: 'baseline',
        gap: 10,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: 10,
        },
    },
    btnFilterText: {
        background: 'transparent',
        boxShadow: 'none',
        padding: 0,
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderColor: 'transparent',
        },
        '& .MuiButton-label': {
            fontSize: 13,
            color: PRIMARY,
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            marginRight: 20,
        },
    },
    filterItem: {
        border: `1px solid ${GRAY_LIGHT}`,
        color: GRAY_LIGHT,
        fontSize: 13,
        padding: '5px 10px',
        height: 30,
        borderRadius: 4,
        maxWidth: 190,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
    },
    filterItemLabel: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        alignSelf: 'baseline',
    },
    closeButton: {
        height: 16,
        width: 16,
        backgroundColor: GRAY_LIGHT,
        padding: 0,
        '&:hover': {
            backgroundColor: GRAY_LIGHT,
        },
    },
    closeIcon: {
        height: 12,
        width: 12,
        color: 'white',
    },
    tooltip: {
        backgroundColor: 'white',
        color: PRIMARY_DARK,
        minWidth: 110,
        boxShadow: '0px 3px 10px 0px rgba(213, 209, 218, 0.50), 0px 0px 6px 0px rgba(213, 209, 218, 0.30)',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: '8px 10px',
    },
    tooltipArrow: {
        color: 'white',
    },
}));

export default useStyles;
