import { makeStyles } from '@material-ui/core/styles';
import {
    PRIMARY, GRAY_BG, PRIMARY_DARK, GRAY_LIGHT,
    DISABLED,
} from '@theme_color';
import { FONT_DEFAULT } from '@theme_typography';

const useStyles = makeStyles(() => ({
    calendarAdmin: {
        fontFamily: `${FONT_DEFAULT} !important`,
        width: 314,
        top: 50,
        '&.rc-calendar': {
            borderRadius: 8,
        },
        '& .rc-calendar-header': {
            backgroundColor: '#F4F7FD',
            padding: '8px 20px',
            height: '100%',
            '& a': {
                color: 'black',
                fontWeight: 700,
                fontSize: 16,
                padding: 0,
            },
        },
        '& .rc-calendar-my-select': {
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
        },
        '& .rc-calendar-body': {
            padding: '12px 20px',
            height: '100%',
            '& .rc-calendar-cell': {
                paddingTop: 6,
                paddingBottom: 6,
            },
        },
        '& .rc-calendar-column-header-inner': {
            color: PRIMARY,
            fontSize: 14,
            fontWeight: 700,
        },
        '& .rc-calendar-date': {
            color: 'black',
            fontSize: 14,
            display: 'grid',
            placeItems: 'center',
            width: 30,
            height: 30,
        },
        '& .rc-calendar-last-month-cell .rc-calendar-date, .rc-calendar-next-month-btn-day .rc-calendar-date': {
            color: '#D0D9E0',
        },
        '& .rc-calendar-today .rc-calendar-date': {
            borderColor: 'transparent',
        },
        '& .rc-calendar-selected-date .rc-calendar-date': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-selected-date .rc-calendar-date:hover': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-date:hover': {
            color: PRIMARY,
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-selected-day .rc-calendar-date': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-footer-btn': {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 10,
            padding: '0 10px',
            '& .rc-calendar-time-picker-btn': {
                textTransform: 'capitalize',
                fontSize: 13,
                color: PRIMARY,
            },
            '& .rc-calendar-time-picker-btn-disabled': {
                color: DISABLED,
                cursor: 'not-allowed',
            },
            '& .rc-calendar-today-btn ': {
                fontSize: 13,
                color: PRIMARY,
                padding: '0 12px',
                margin: 0,
                float: 'unset',
            },
        },

        // time picker
        '& .rc-time-picker-panel-inner': {
            borderRadius: 0,
            width: '100%',
        },
        '& .rc-calendar-time-picker': {
            top: 'unset',
            height: 306,
        },
        '& .rc-time-picker-panel-select': {
            width: 103,
            maxHeight: 286,
            marginTop: 10,
            '& li': {
                fontSize: 14,
                color: 'black',
                width: '50%',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
                marginBottom: 10,
                borderRadius: 8,
                border: '1px solid transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${PRIMARY}`,
                    color: PRIMARY,
                    fontWeight: 700,
                },
            },
            '& li.rc-time-picker-panel-select-option-selected': {
                color: 'white',
                fontWeight: 700,
                backgroundColor: PRIMARY,
                width: '50%',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
                marginBottom: 10,
                borderRadius: 8,
                border: 0,
                '&:hover': {
                    color: 'white',
                    backgroundColor: PRIMARY,
                    border: 0,
                },
            },
        },

        // year picker
        '& .rc-calendar-year-panel-header': {
            backgroundColor: '#F4F7FD',
        },
        '& .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year': {
            backgroundColor: PRIMARY,
            color: 'white',
            border: 0,
            fontWeight: '700 !important',
            '&:hover': {
                color: 'white',
                backgroundColor: PRIMARY,
                border: 0,
            },
        },
        '& .rc-calendar-year-panel-year': {
            fontWeight: '400 !important',
            border: '1px solid transparent',
        },
        '& .rc-calendar-year-panel-year:hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            color: PRIMARY,
            fontWeight: '700 !important',
        },

        // year range
        '& .rc-calendar-decade-panel-header': {
            backgroundColor: '#F4F7FD',
        },
        '& .rc-calendar-decade-panel-century': {
            fontSize: 16,
            fontWeight: 700,
        },
        '& .rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade': {
            fontWeight: '700 !important',
            backgroundColor: PRIMARY,
            color: 'white',
            border: 0,
            '&:hover': {
                color: 'white',
                backgroundColor: PRIMARY,
                border: 0,
            },
        },
        '& .rc-calendar-decade-panel-decade': {
            fontWeight: '400 !important',
            border: '1px solid transparent',
        },
        '& .rc-calendar-decade-panel-decade:hover': {
            fontWeight: '700 !important',
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            color: PRIMARY,
        },

        // input
        '& .rc-calendar-input-wrap': {
            color: 'black',
            fontFamily: `${FONT_DEFAULT} !important`,
            padding: 6,
            fontSize: 14,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        '& .rc-calendar-input ': {
            color: 'black',
            fontFamily: `${FONT_DEFAULT} !important`,
            fontSize: 14,
        },
        '& .rc-calendar-clear-btn:after': {
            color: 'black',
            fontSize: 14,
        },
    },
    calendar: {
        fontFamily: `${FONT_DEFAULT} !important`,
        width: 314,
        '&.rc-calendar': {
            borderRadius: 8,
        },
        '& .rc-calendar-header': {
            backgroundColor: GRAY_LIGHT,
            padding: '8px 20px',
            height: '100%',
            '& a': {
                color: 'black',
                fontWeight: 700,
                fontSize: 16,
                padding: 0,
            },
        },
        '& .rc-calendar-my-select': {
            display: 'flex',
            gap: 10,
            justifyContent: 'center',
        },
        '& .rc-calendar-body': {
            padding: '12px 20px',
            height: '100%',
            '& .rc-calendar-cell': {
                paddingTop: 6,
                paddingBottom: 6,
            },
        },
        '& .rc-calendar-column-header-inner': {
            color: PRIMARY_DARK,
            fontSize: 14,
            fontWeight: 700,
        },
        '& .rc-calendar-date': {
            color: PRIMARY_DARK,
            fontSize: 14,
            display: 'grid',
            placeItems: 'center',
            width: 30,
            height: 30,
        },
        '& .rc-calendar-last-month-cell .rc-calendar-date, .rc-calendar-next-month-btn-day .rc-calendar-date': {
            color: '#D0D9E0',
        },
        '& .rc-calendar-today .rc-calendar-date': {
            borderColor: 'transparent',
        },
        '& .rc-calendar-selected-date .rc-calendar-date': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-selected-date .rc-calendar-date:hover': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-date:hover': {
            color: PRIMARY,
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-selected-day .rc-calendar-date': {
            color: 'white',
            backgroundColor: PRIMARY,
            borderRadius: 20,
            fontWeight: 700,
        },
        '& .rc-calendar-footer-btn': {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 10,
            padding: '0 10px',
            '& .rc-calendar-time-picker-btn': {
                textTransform: 'capitalize',
                fontSize: 13,
                color: PRIMARY,
            },
            '& .rc-calendar-time-picker-btn-disabled': {
                color: DISABLED,
                cursor: 'not-allowed',
            },
            '& .rc-calendar-today-btn ': {
                fontSize: 13,
                color: PRIMARY,
                padding: '0 12px',
                margin: 0,
                float: 'unset',
            },
        },
        '& .rc-calendar-disabled-cell': {
            '& .rc-calendar-date': {
                background: 'transparent',
                color: DISABLED,
            },
            '& .rc-calendar-date:hover': {
                color: DISABLED,
                backgroundColor: 'transparent',
                border: '0',
            },
        },

        // time picker
        '& .rc-time-picker-panel-inner': {
            borderRadius: 0,
            width: '100%',
        },
        '& .rc-calendar-time-picker': {
            top: 'unset',
            height: 306,
        },
        '& .rc-time-picker-panel-select': {
            width: 103,
            maxHeight: 286,
            marginTop: 10,
            '& li': {
                fontSize: 14,
                color: 'black',
                width: '50%',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
                marginBottom: 10,
                borderRadius: 8,
                border: '1px solid transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${PRIMARY}`,
                    color: PRIMARY,
                    fontWeight: 700,
                },
            },
            '& li.rc-time-picker-panel-select-option-selected': {
                color: 'white',
                fontWeight: 700,
                backgroundColor: PRIMARY,
                width: '50%',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
                marginBottom: 10,
                borderRadius: 8,
                border: 0,
                '&:hover': {
                    color: 'white',
                    backgroundColor: PRIMARY,
                    border: 0,
                },
            },
        },

        // year picker
        '& .rc-calendar-year-panel-header': {
            backgroundColor: GRAY_LIGHT,
        },
        '& .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year': {
            backgroundColor: PRIMARY,
            color: 'white',
            border: 0,
            fontWeight: '700 !important',
            '&:hover': {
                color: 'white',
                backgroundColor: PRIMARY,
                border: 0,
            },
        },
        '& .rc-calendar-year-panel-year': {
            fontWeight: '400 !important',
            border: '1px solid transparent',
        },
        '& .rc-calendar-year-panel-year:hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            color: PRIMARY,
            fontWeight: '700 !important',
        },

        // year range
        '& .rc-calendar-decade-panel-header': {
            backgroundColor: GRAY_LIGHT,
        },
        '& .rc-calendar-decade-panel-century': {
            fontSize: 16,
            fontWeight: 700,
        },
        '& .rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade': {
            fontWeight: '700 !important',
            backgroundColor: PRIMARY,
            color: 'white',
            border: 0,
            '&:hover': {
                color: 'white',
                backgroundColor: PRIMARY,
                border: 0,
            },
        },
        '& .rc-calendar-decade-panel-decade': {
            fontWeight: '400 !important',
            border: '1px solid transparent',
        },
        '& .rc-calendar-decade-panel-decade:hover': {
            fontWeight: '700 !important',
            backgroundColor: 'transparent',
            border: `1px solid ${PRIMARY}`,
            color: PRIMARY,
        },

        // input
        '& .rc-calendar-input-wrap': {
            backgroundColor: GRAY_BG,
            color: 'black',
            fontFamily: `${FONT_DEFAULT} !important`,
            padding: 6,
            fontSize: 14,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        '& .rc-calendar-input ': {
            backgroundColor: GRAY_BG,
            color: 'black',
            fontFamily: `${FONT_DEFAULT} !important`,
            fontSize: 14,
        },
        '& .rc-calendar-clear-btn:after': {
            color: 'black',
            fontSize: 14,
        },
    },
    textInput: {
        width: '100%',
        '&:hover': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root': {
            backgroundColor: GRAY_BG,
            borderRadius: 6,
            padding: '5px 10px',
            fontSize: 14,
        },
        '& .MuiAutocomplete-input': {
            padding: '0 !important',
        },
        '& .MuiAutocomplete-inputRoot': {
            minHeight: 42,
            borderBottom: 'none',
            paddingTop: 10,
            paddingBottom: 10,
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
        '& .MuiInputBase-input::placeholder': {
            color: PRIMARY_DARK,
            fontSize: 13,
        },
        '&.adorn': {
            '& .MuiInput-root': {
                padding: 0,
            },
        },
        '& .MuiChip-root': {
            backgroundColor: 'transparent',
            border: `1px solid ${GRAY_LIGHT}`,
            borderRadius: 8,
        },
        '& .MuiChip-deleteIcon': {
            fill: PRIMARY_DARK,
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingBottom: 10,
        },
        '& textarea': {
            '&::-webkit-scrollbar': {
                width: '.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: GRAY_LIGHT,
                borderRadius: 5,
            },
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '& input': {
                cursor: 'not-allowed',
            },
        },
    },
    fieldInput: {
        border: '1px solid',
        borderRadius: '20px !important',
        minHeight: 35,
        padding: 0,
        '&.disabled': {
            borderColor: '#DDE1EC',
        },
        '& .MuiOutlinedInput-input': {
            padding: '7px 0px',
            paddingLeft: 14,
        },
        '&.adorn': {
            paddingLeft: 14,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 13,
        },
    },
}));

export default useStyles;
