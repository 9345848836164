import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
            // Default left padding is 6px
            padding: 4,
        },
        '& .MuiOutlinedInput-root': {
            border: '0px !important',
        },
    },
}));

export default useStyles;
