/* eslint-disable eqeqeq */
import clsx from 'clsx';
import { useTranslation } from '@i18n';

import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@common_button';
import TableFilters from '@common_tableseller/components/Filter';
import Tabs from '@common_tabsseller';
import TextField from '@common_textfield';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import useStyles from '@common_tableseller/style';

const TabsHeader = (props) => {
    const {
        header = '',
        searchPlaceholder = '',
        hideTitle = false,
        hideToolbar = false,
        hideSearch = false,
        hideFilters = false,
        toolActionsRight = [],
        toolActionsLeft = [],
        initialFilters,
        columns,

        filters = [],
        setFilters,
        setSearch,
        setExpandedToolbar,
        expandedToolbar,
        dataTab = [],
        tab,
        setTab,
        onChangeTab = () => { },
        search,
    } = props;
    const { t } = useTranslation('common');
    const classes = useStyles();

    const getArrowClass = (anchor) => (anchor ? classes.arrowUp : classes.arrowDown);
    return (
        <Paper className={classes.paperHead}>
            {!hideTitle
                && (
                    <div className={clsx(classes.header, 'nopad')}>
                        {header}
                    </div>
                )}
            <Tabs data={dataTab} onChange={(e, v) => { onChangeTab(v); setTab(v); }} value={tab} allItems={false} />
            {!hideToolbar
                && (
                    <div className={classes.tableToolbar}>
                        <div className="top-buttons-wrapper nopad">
                            <div className="top-item-left">
                                {!hideSearch
                                    && (
                                        <div className="top-item">
                                            <TextField
                                                name="email"
                                                placeholder={searchPlaceholder}
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                className={classes.textInput}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <img alt="" src="/assets/img/search.svg" className={classes.iconImg} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: search === '' ? false : (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setSearch('')} classes={{ root: classes.cancelSearch }}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}

                                {toolActionsLeft.length
                                    ? (
                                        <div className="top-item-right">
                                            {toolActionsLeft.map((component) => (
                                                <div className="top-item">
                                                    {component}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                    : null}
                            </div>
                            <div className="top-item-right">
                                {toolActionsRight.length
                                    ? (toolActionsRight.map((component) => (
                                        <div className="top-item">
                                            {component}
                                        </div>
                                    ))
                                    )
                                    : null}
                                {!hideFilters && !!filters.length
                                    && (
                                        <div className="top-item">
                                            <Button
                                                className={clsx(classes.btnAction, 'filter')}
                                                onClick={() => setExpandedToolbar(expandedToolbar != 'filters' ? 'filters' : '')}
                                                endIcon={
                                                    <KeyboardArrowRightIcon className={getArrowClass(expandedToolbar)} />
                                                }
                                            >
                                                <span className={classes.btnText}>
                                                    {t('common:Filters')}
                                                </span>
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <TableFilters
                            {...props}
                            t={t}
                            columns={columns}
                            resettedFilter={initialFilters.map((filter) => ({ ...filter, value: filter.initialValue }))}
                            initialFilters={filters}
                            setParentFilters={setFilters}
                            expandedToolbar={expandedToolbar}
                            setExpandedToolbar={setExpandedToolbar}
                        />
                    </div>
                )}
        </Paper>
    );
};

export default TabsHeader;
