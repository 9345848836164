/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getComponentOrString } from '@common_tableseller/helpers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_LIGHT, PRIMARY_DARK } from '@theme_color';

const useStyles = makeStyles(() => ({
    btnMore: {
        padding: 0,
    },
    iconMore: {
        fill: PRIMARY_DARK,
    },
    menuAction: {
        '& .MuiMenuItem-root': {
            fontSize: 13,
            color: PRIMARY_DARK,
        },
        '& .MuiMenu-paper': {
            marginTop: -10,
        },
        '& .MuiMenu-list': {
            borderRadius: 8,
            border: `1px solid ${GRAY_LIGHT}`,
        },
    },
    menuItemLink: {
        fontSize: 13,
        color: PRIMARY_DARK,
        padding: '5px 16px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    disabled: {
        color: GRAY_LIGHT,
        cursor: 'not-allowed',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const MenuPopover = (props) => {
    const {
        openButton,
        menuItems,
        buttonType = 'primary-rounded',
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClickOpenButton = (event) => {
        setAnchorEl(event.currentTarget);
        if (openButton && openButton.onClick) openButton.onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuItem = (onClick) => {
        handleClose();
        if (onClick) onClick();
    };

    return (
        <div>
            <IconButton
                buttonType={buttonType}
                onClick={handleClickOpenButton}
                className={classes.btnMore}
            >
                <MoreVertIcon classes={{ root: classes.iconMore }} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                className={classes.menuAction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {menuItems.map((menuItem, i) => (menuItem.hide ? null
                    : (menuItem.link
                        ? (
                            <MenuItem
                                key={i}
                                component={() => (
                                    <li className={classes.menuItemLink}>
                                        <Link href={menuItem.link}>
                                            <a>{menuItem.label}</a>
                                        </Link>
                                    </li>
                                )}
                            />
                        )
                        : (
                            <MenuItem
                                key={i}
                                onClick={() => !menuItem.disabled && handleClickMenuItem(menuItem.onClick)}
                                className={menuItem.disabled && classes.disabled}
                            >
                                {getComponentOrString(menuItem.label)}
                            </MenuItem>
                        )
                    )
                ))}
            </Menu>
        </div>
    );
};

export default MenuPopover;
