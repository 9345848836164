/* eslint-disable prefer-destructuring */
import { useRouter } from 'next/router';

import Table from '@common_tableseller';
import Button from '@common_button';

import gqlService from '@sellermodules/order/services/graphql';

import useStyles from '@sellermodules/order/pages/list/components/ListCard/style';

const ExportOrder = (props) => {
    const {
        t,
    } = props;
    const router = useRouter();
    const classes = useStyles();

    const [getExportHistory, { data, loading }] = gqlService.getExportHistory();

    const histories = data?.getExportHistory?.items || [];
    const historiesTotal = data?.getExportHistory?.total_count || 0;

    const columns = [
        {
            field: 'export_at', headerName: t('sellerorder:Date'), sortable: true, initialSort: 'DESC', align: 'middle',
        },
        { field: 'user', headerName: t('sellerorder:Export_By'), align: 'middle' },
        { field: 'data_count', headerName: t('sellerorder:Total_Data'), align: 'middle' },
        { field: 'action', headerName: '', align: 'middle' },
    ];

    const filters = [
        {
            field: 'export_type', name: 'export_type', type: 'eq', initialValue: 'order_report',
        },
    ];

    const rows = histories.map((hist) => ({
        ...hist,
        action: (
            <div className={classes.center}>
                <Button buttonType="outlined" user="seller" onClick={() => router.push(hist.url)}>
                    {t('sellercatalogproduct:Download')}
                </Button>
            </div>),
    }));

    return (
        <Table
            columns={columns}
            rows={rows}
            getRows={getExportHistory}
            loading={loading}
            count={historiesTotal}
            t={t}
            filters={filters}
            hideHead
            hideFilters
            hideToolbar
            initialRowsPerPage={5}
        />
    );
};

export default ExportOrder;
