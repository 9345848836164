import { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';

import TextField from '@common_textfield';
import Autocomplete from '@common_autocomplete';
import Button from '@common_button';
import Calendar from '@common_calendar';

import useStyles from '@common_tableseller/components/Filter/style';

const TabsHeader = (props) => {
    const {
        t, columns, expandedToolbar, setExpandedToolbar, initialFilters, setParentFilters,
    } = props;
    const classes = useStyles();
    const [filters, setFilters] = useState(initialFilters);

    const applyFilter = () => {
        setExpandedToolbar(false);
        setParentFilters(filters);
    };

    const cancelFilter = () => {
        setExpandedToolbar(false);
    };

    const setFilterValueByField = (field, value, fieldParent) => {
        if (fieldParent) {
            const indexParent = filters.findIndex((filter) => filter.name === fieldParent.name);
            if (indexParent >= 0) {
                setFilters(
                    filters.map((filter) => ({
                        ...filter,
                        ...(filter.name === fieldParent.name && {
                            ...fieldParent,
                            children: filter.children.map((filterChild) => ({
                                ...filterChild,
                                ...(filterChild.name === field.name && { ...filterChild, value }),
                            })),
                        }),
                    })),
                );
            }
        } else {
            const index = filters.findIndex((filter) => filter.name === field.name);
            if (index >= 0) {
                setFilters(
                    filters.map((filter) => ({
                        ...filter,
                        ...(filter.name === field.name && { ...field, value }),
                    })),
                );
            }
        }
    };

    const renderComponent = (filterProps, filterParent) => {
        const {
            frontend_input, options, placeholder, label, multiple, value, renderOption,
            primaryKey = 'value', labelKey = 'label', inputType = 'string', type, format = 'YYYY-MM-DD HH:mm:ss',
        } = filterProps;

        const filterValue = () => {
            let val = value;
            if (inputType === 'date' && ['from', 'to'].includes(type)) {
                val = value?.split(' ').slice(0, 1).join('');
            }
            return val;
        };

        switch (frontend_input) {
        case 'select':
            return (
                <Autocomplete
                    multiple={multiple}
                    className={clsx(classes.textInputAutoComplete, value?.length && 'hasValue')}
                    options={options}
                    classes={{
                        root: clsx(classes.rootAutocomplete, multiple && 'multiple'),
                        listbox: classes.listboxAutocomplete,
                        paper: classes.paperAutocomplete,
                        popper: classes.popperAutocomplete,
                        option: classes.optionAutocomplete,
                    }}
                    limitTags={1}
                    value={value}
                    onChange={(e) => setFilterValueByField(filterProps, e, filterParent)}
                    onInputChange={(e) => (e)}
                    primaryKey={primaryKey}
                    labelKey={labelKey}
                    renderOption={(e) => (renderOption ? renderOption(e) : e[labelKey])}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textInputAutoComplete}
                            // eslint-disable-next-line no-nested-ternary
                            placeholder={value?.length ? '' : placeholder || `${t('Select')} ${label}`}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    applyFilter();
                                }
                            }}
                        />
                    )}
                />
            );
        case 'date':
        case 'datetime':
            return (
                <Calendar
                    showToday
                    useTime={frontend_input === 'datetime'}
                    format={format}
                    date={(value && moment(value).format(format)) || ''}
                    setDate={(v) => setFilterValueByField(filterProps, v ? moment(v).format(format) : '', filterParent)}
                    textFieldProps={{
                        className: classes.textInput,
                        placeholder: placeholder || `${t('Input')} ${label}`,
                        onKeyPress: (ev) => {
                            if (ev.key === 'Enter') {
                                applyFilter();
                            }
                        },
                    }}
                />
            );
        default:
            return (
                <TextField
                    className={classes.textInput}
                    placeholder={placeholder || `${t('Input')} ${label}`}
                    value={filterValue() || ''}
                    onChange={(e) => {
                        let val = e.target.value;
                        if (inputType === 'date') {
                            if (type === 'from') {
                                val = `${val} 00:00:00`;
                            }
                            if (type === 'to') {
                                val = `${val} 23:59:59`;
                            }
                        }
                        setFilterValueByField(filterProps, val, filterParent);
                    }}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            applyFilter();
                        }
                    }}
                    type={inputType}
                />
            );
        }
    };

    const isHidden = (name) => columns.find((col) => col.field === name)?.hidden;

    useEffect(() => {
        setFilters(initialFilters);
    }, [expandedToolbar]);

    return (
        <Collapse in={expandedToolbar}>
            <Paper classes={{ root: classes.paperRoot }} elevation={0}>
                <div className={classes.gridFilters}>
                    {filters.filter(({ name, hidden }) => !isHidden(name) && !hidden).map((filter, i) => (
                        <div key={i}>
                            <div className={classes.filterLabel}>{filter.label}</div>
                            {filter.children?.length ? (
                                <div className={classes.gridColumnInput}>
                                    {filter.children?.map((filterInput, fIdx) => (
                                        <div key={fIdx}>
                                            <div className={classes.inputFlex}>
                                                <div className={classes.inputLabel}>{filterInput.label}</div>
                                                {renderComponent(filterInput, filter)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                                : (renderComponent(filter))}
                        </div>
                    ))}
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={cancelFilter}
                        buttonType="buttonText"
                        className={classes.buttonText}
                    >
                        {t('common:Cancel')}
                    </Button>
                    <Button
                        onClick={applyFilter}
                        className={classes.button}
                    >
                        {t('common:Apply_Filters')}
                    </Button>
                </div>
            </Paper>
        </Collapse>
    );
};

export default TabsHeader;
