import dayjs from 'dayjs';

const formatDate = (date = new Date(), format = 'MMMM D, YYYY') => {
    if (date) {
        return dayjs(date).format(format);
    }
    return '';
};

export const getMinDate = (limit = 3) => {
    const currentDate = new Date();
    const getTime = currentDate.getTime();
    const getPastTime = getTime - (60 * 60 * 24 * limit * 30 * 1000);
    const newTime = new Date(getPastTime);
    return newTime;
};

export default formatDate;
