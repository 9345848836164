/* eslint-disable max-len */

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    PRIMARY, TABLE_GRAY, FONT_COLOR, PRIMARY_DARK, GRAY_LIGHT, WHITE,
} from '@theme_color';

const font = '"Roboto", "Helvetica", "Arial", sans-serif';
const useStyles = makeStyles((theme) => ({
    tableToolbar: {
        '& .top-buttons-wrapper': {
            padding: 0,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                display: 'block',
            },
            justifyContent: 'space-between',
        },
        '& .top-item-left': {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },
        '& .top-item-right': {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right',
            justifyContent: 'end',
            [theme.breakpoints.down('md')]: {
                marginTop: 10,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                textAlign: 'left',
            },
            '& .top-item': {
                [theme.breakpoints.down('sm')]: {
                    marginTop: 20,
                },
            },
        },
        '& .top-item': {
            display: 'inline-block',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                textAlign: 'left',
                '& .MuiPickersDateRangePickerInput-root': {
                    alignItems: 'inherit',
                },
            },
            marginRight: '12px',
            '& .MuiButton-text': {
                border: '1px solid',
                color: FONT_COLOR,
                textTransform: 'capitalize',
            },
        },
    },
    head: {
        display: 'flex',
        alignItems: 'center',
        '& .icon': {
            width: 18,
            height: 18,
        },
        '&.end': {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'end',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: 10,
                paddingLeft: 40,
            },
        },
    },
    expandContainer: {
        background: 'white',
        '& .filter-item': {
            border: `1px solid ${GRAY_LIGHT}`,
            color: GRAY_LIGHT,
            fontSize: 13,
            padding: '5px 10px',
            minHeight: 26,
            borderRadius: 4,
            margin: 10,
            marginTop: 0,
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 87,
            alignItems: 'center',
        },
    },
    expandGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 11fr',
        alignItems: 'baseline',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: 10,
        },
    },
    expandContainerTab: {
        margin: '15px 0 15px 0',
    },
    btnTab: {
        background: 'transparent',
        boxShadow: 'none',
        border: `1px solid ${GRAY_LIGHT}`,
        fontSize: 13,
        color: PRIMARY_DARK,
        padding: '8px 18px',
        height: 32,
        borderRadius: 4,
        marginTop: 0,
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 87,
        '&:hover': {
            background: 'transparent',
            boxShadow: 'none',
            border: `1px solid ${GRAY_LIGHT}`,
        },
        '&.active': {
            background: PRIMARY,
            border: `1px solid ${PRIMARY}`,
            color: 'white',
            '&:hover': {
                background: PRIMARY,
                border: `1px solid ${PRIMARY}`,
                color: 'white',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: 8,
        },
    },
    btnFilterText: {
        background: 'transparent',
        boxShadow: 'none',
        padding: 0,
        marginRight: 30,
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderColor: 'transparent',
        },
        '& .MuiButton-label': {
            fontSize: 13,
            color: PRIMARY,
            fontWeight: 'bold',
        },
        whiteSpace: 'nowrap',
    },
    closeButton: {
        height: 16,
        width: 16,
        backgroundColor: GRAY_LIGHT,
        padding: 0,
        marginLeft: 15,
        '&:hover': {
            backgroundColor: GRAY_LIGHT,
        },
    },
    closeIcon: {
        height: 12,
        width: 12,
        color: 'white',
    },
    arrowDown: {
        transition: 'all .2s linear',
        transform: 'rotate(90deg)',
    },
    arrowUp: {
        transition: 'all .2s linear',
        transform: 'rotate(-90deg)',
    },
    paperHead: {
        boxShadow: '0px 3px 15px #4D2F821A',
        marginBottom: 10,
        padding: '20px 30px',
        borderRadius: 8,
    },
    tableContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 8,
        boxShadow: '0px 3px 15px #4D2F821A',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100vw',
            display: 'block',
            marginLeft: '0px',
            marginRight: '0px',
        },
        '&.footer': {
            marginTop: 20,
        },
    },
    checkAllContainer: {
        padding: '0px 30px',
        marginBottom: -5,
        color: PRIMARY_DARK,
        alignItems: 'center',
        '&.MuiGrid-spacing-xs-3': {
            width: '100%',
        },
    },
    controlLabel: {
        '& .MuiFormControlLabel-label': {
            fontFamily: font,
            fontSize: 13,
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
            },
            fontWeight: 'bold',
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: PRIMARY,
        },
        '& .MuiCheckbox-root': {
            color: GRAY_LIGHT,
            borderRadius: 4,
        },
        '&.MuiFormControlLabel-root': {
            marginBottom: 0,
        },
    },
    rowPaper: {
        boxShadow: '0px 3px 15px #4D2F821A',
        marginBottom: 20,
        padding: '5px 0 15px 0',
        borderRadius: 8,
        color: PRIMARY_DARK,
        fontSize: 13,
    },
    rowHead: {
        display: 'flex',
        borderBottom: `2px inset ${TABLE_GRAY}`,
        padding: '0px 20px',
        marginBottom: 20,
        textTransform: 'none',
        '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: 'transparent !important',
        },
        '& .MuiIconButton-colorSecondary:hover': {
            backgroundColor: 'transparent !important',
        },
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            paddingBottom: 10,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    divider: {
        display: 'flex',
        marginLeft: 5,
    },
    rowFooter: {
        padding: '0px 30px',
        fontWeight: 600,
    },
    rowBody: {
        padding: '0px 20px',
        '& .box': {
            [theme.breakpoints.down('xs')]: {
                borderRight: '0',
                borderBottom: `1px inset ${TABLE_GRAY}`,
                padding: 10,
            },
            borderRight: `1px inset ${TABLE_GRAY}`,
            padding: '0 20px',
            '& .title': {
                fontWeight: 'bold',
                marginBottom: 15,
            },
            margin: 10,
            '&:last-child': {
                borderColor: 'transparent',
            },
            cursor: 'pointer',
        },
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '2fr 1fr 1fr',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    loading: {
        display: 'flex',
        color: PRIMARY_DARK,
        fontWeight: 600,
        justifyContent: 'center',
        padding: '20px 0',
    },
    tablePagination: {
        marginTop: 10,
        '& .MuiTablePagination-spacer': {
            display: 'none',
        },
        '& .MuiTablePagination-caption': {
            color: PRIMARY_DARK,
            fontWeight: 600,
        },
        '& .MuiTablePagination-select': {
            color: PRIMARY_DARK,
            fontWeight: 600,
            border: `1px solid ${GRAY_LIGHT}`,
            borderRadius: '8px',
            marginLeft: 10,
            paddingLeft: 15,
            paddingRight: 30,
        },
        '& .MuiTablePagination-toolbar': {
            display: 'grid',
            gridTemplateColumns: '0fr 0fr 2fr 7fr',
            [theme.breakpoints.up('xl')]: {
                gridTemplateColumns: '0fr 0fr 3fr 7fr',
            },
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: '0fr 0fr 1fr 6fr',
            },
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: '0fr 0fr 0fr 6fr',
            },
            paddingTop: 15,
            paddingBottom: 15,
        },
    },
    paginationRoot: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
    },
    paginationAction: {
        '& .MuiPaginationItem-outlined': {
            borderColor: GRAY_LIGHT,
            color: GRAY_LIGHT,
            fontWeight: 600,
            '&:hover': {
                borderColor: PRIMARY_DARK,
                color: PRIMARY_DARK,
            },
        },
        '& .MuiPaginationItem-page.Mui-selected': {
            borderColor: PRIMARY,
            color: PRIMARY,
            backgroundColor: 'transparent',
        },
        '& .MuiPagination-ul': {
            [theme.breakpoints.down('md')]: {
                flexWrap: 'nowrap',
            },
        },
        margin: '0 20px',
    },
    btnPagination: {
        background: PRIMARY,
        borderRadius: '10%',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: PRIMARY_DARK,
            boxShadow: 'none',
            borderColor: PRIMARY_DARK,
        },
        '& .MuiButton-label': {
            fontSize: 13,
        },
        '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: GRAY_LIGHT,
            color: 'white',
        },
    },
    alignTop: {
        verticalAlign: 'top',
    },
    checkbox: {
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: PRIMARY,
        },
        '&.MuiCheckbox-root': {
            color: GRAY_LIGHT,
            borderRadius: 4,
        },
    },
    header: {
        color: PRIMARY_DARK,
        fontSize: 20,
        fontWeight: 'bold',
        padding: '30px 0 0 30px',
        '&.nopad': {
            padding: 0,
        },
    },
    textInput: {
        width: 280,
        '&.full': {
            width: 430,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&.full': {
                width: '100%',
            },
        },
        '&:hover': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root': {
            backgroundColor: TABLE_GRAY,
            borderRadius: 6,
            padding: '5px 10px',
        },
        '& .MuiAutocomplete-inputRoot': {
            height: 42,
            borderBottom: 'none',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
        '& .MuiInputBase-input::placeholder': {
            color: PRIMARY_DARK,
            fontSize: 13,
        },
    },
    iconImg: {
        padding: '0 5px 0 10px',
    },
    btnAction: {
        borderRadius: 6,
        background: 'unset',
        boxShadow: 'none',
        textTransform: 'capitalize',
        border: '1px solid',
        borderColor: TABLE_GRAY,
        padding: '12px 15px',
        height: 42,
        '&:hover': {
            backgroundColor: TABLE_GRAY,
            boxShadow: 'none',
        },
        '&.check': {
            height: 32,
            background: 'white',
            borderColor: GRAY_LIGHT,
            color: PRIMARY_DARK,
            fontSize: 13,
            fontWeight: 600,
            boxShadow: 'none',
            padding: '7px 15px',
            '&:hover': {
                backgroundColor: 'white',
                boxShadow: 'none',
                borderColor: GRAY_LIGHT,
            },
            '&.MuiButton-contained.Mui-disabled': {
                backgroundColor: GRAY_LIGHT,
                color: 'white',
            },
        },
    },
    btnFilter: {
        borderRadius: 6,
        background: PRIMARY,
        boxShadow: 'none',
        textTransform: 'capitalize',
        border: '1px solid',
        borderColor: PRIMARY,
        padding: '12px 15px',
        height: 42,
        fontSize: 13,
        fontWeight: 600,
        '&:active': {
            backgroundColor: PRIMARY_DARK,
            boxShadow: 'none',
            borderColor: PRIMARY_DARK,
        },
        '&:hover': {
            backgroundColor: PRIMARY_DARK,
            boxShadow: 'none',
            borderColor: PRIMARY_DARK,
        },
        '&.gray': {
            background: TABLE_GRAY,
            borderColor: TABLE_GRAY,
            color: PRIMARY_DARK,
            '&:hover': {
                backgroundColor: TABLE_GRAY,
                boxShadow: 'none',
                borderColor: TABLE_GRAY,
            },
        },
    },
    menuAction: {
        '& .MuiMenuItem-root': {
            fontSize: 13,
            color: PRIMARY_DARK,
        },
        '& .MuiMenu-list': {
            borderRadius: 6,
            background: TABLE_GRAY,
        },
        '& .MuiListItem-button:hover': {
            background: TABLE_GRAY,
        },
        '& .MuiMenu-paper': {
            marginTop: 5,
        },
    },
    menuItem: {
        '&.parent': {
            color: `${PRIMARY_DARK} !important`,
            fontSize: '13px !important',
            backgroundColor: `${TABLE_GRAY} !important`,
            borderRadius: 6,
        },
        color: 'white !important',
        fontSize: '13px !important',
        backgroundColor: `${GRAY_LIGHT} !important`,
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        background: TABLE_GRAY,
        borderRadius: 6,
        height: 42,
        padding: '13px 15px',
        '& .MuiTypography-body1': {
            margin: 0,
        },
        '& .MuiSvgIcon-root': {
            fill: PRIMARY_DARK,
            width: 17,
            height: 17,
        },
        '& .MuiPickersDay-root.Mui-selected': {
            backgroundColor: PRIMARY_DARK,
        },
    },
    inputDate: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        '&:hover': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-root': {
            backgroundColor: 'transparent',
            borderRadius: 6,
            width: 90,
        },
        '& .MuiInputBase-input': {
            fontSize: 13,
            fontWeight: 600,
            color: PRIMARY_DARK,
            textAlign: 'center',
        },
        '& .MuiAutocomplete-inputRoot': {
            height: 42,
            borderBottom: 'none',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
    },
    checkboxOption: {
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'white',
        },
        '& .MuiCheckbox-root': {
            color: 'white',
            borderRadius: 4,
            padding: 10,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 13,
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
            },
        },
        '&.MuiFormControlLabel-root': {
            minWidth: '100%',
            marginBottom: 0,
            height: 25,
        },
    },
    dialogContainer: {
        padding: '15px 20px',
        borderRadius: 8,
        position: 'relative',
        minWidth: 768,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset',
        },
        '& .MuiPickersStaticWrapper-root': {
            overflow: 'unset',
            [theme.breakpoints.up('sm')]: {
                minWidth: 562,
            },
        },
        '& .MuiDialogContent-root': {
            overflowY: 'hidden',
            [theme.breakpoints.down('xs')]: {
                overflowY: 'auto',
            },
        },
        '& .MuiPickersDesktopDateRangeCalendar-arrowSwitcher': {
            background: GRAY_LIGHT,
            padding: 8,
            borderRadius: '6px 0 0 6px',
            '& h6': {
                fontWeight: 700,
            },
        },
        '& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer + .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer .MuiPickersDesktopDateRangeCalendar-arrowSwitcher': {
            borderRadius: '0 6px 6px 0',
        },
        '& .MuiPickersArrowSwitcher-iconButton': {
            background: 'unset',
        },
        '& .MuiPickersCalendar-daysHeader span': {
            fontWeight: 700,
            color: PRIMARY_DARK,
        },
        '& .MuiPickersDesktopDateRangeCalendar-calendar': {
            minWidth: 'unset',
            height: 260,
            minHeight: 260,
        },
        '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            border: 0,
            padding: 2,
        },
        '& .MuiPickersDay-root': {
            fontWeight: 700,
            // width: 30,
            // height: 30,
            '&.Mui-disabled': {
                color: GRAY_LIGHT,
            },
        },
        // '& .MuiPickersCalendar-weekDayLabel': {
        //     width: 30,
        //     height: 36,
        // },
    },
    dateRangeClass: {
        '& .MuiPickersDesktopDateRangeCalendar-arrowSwitcher': {
            background: GRAY_LIGHT,
            paddingBottom: 16,
            '& h6': {
                fontWeight: 700,
            },
        },
        '& .MuiPickersArrowSwitcher-iconButton': {
            background: 'unset',
        },
        '& .MuiPickersCalendar-daysHeader span': {
            fontWeight: 700,
            color: PRIMARY_DARK,
        },
        '& .MuiPickersDesktopDateRangeCalendar-calendar': {
            minHeight: 265,
        },
        '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            border: 0,
            padding: 2,
        },
        '& .MuiPickersDay-root': {
            fontWeight: 700,
            '&.Mui-disabled': {
                color: GRAY_LIGHT,
            },
        },
    },
    dialogTitleContainer: {
        textAlign: 'center',
        color: PRIMARY_DARK,
        '& .MuiTypography-root': {
            fontWeight: 'bold',
        },
        '& h2': {
            fontSize: 20,
        },
        '&.MuiDialogTitle-root': {
            padding: 0,
        },
    },
    dialogContentDiv: {
        paddingTop: 20,
    },
    content: {
        color: PRIMARY_DARK,
        fontSize: 14,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        '& .title': {
            fontWeight: 600,
            marginBottom: 10,
        },
        '&.two-columns': {
            display: 'flex',
            padding: 0,
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        '&.MuiDialogContent-root:first-child': {
            paddingTop: 0,
        },
        '&.MuiDialogContent-root': {
            overflowY: 'visible',
        },
    },
    closeButtonDialog: {
        position: 'absolute',
        top: 10,
        right: 10,
        padding: 0,
        color: PRIMARY_DARK,
    },
    divPeriod: {
        width: 200,
        paddingLeft: 40,
        fontSize: 13,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            paddingLeft: 0,
        },
    },
    btnGrid: {
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& hr': {
                display: 'none',
            },
        },
        '& hr': {
            marginBottom: 23,
        },
    },
    btnTime: {
        marginBottom: 15,
        background: TABLE_GRAY,
        color: PRIMARY_DARK,
        letterSpacing: 0,
        padding: '0px 10px',
        boxShadow: 'none',
        marginRight: 10,
        '&.focus': {
            background: GRAY_LIGHT,
            color: WHITE,
        },
        '&:hover': {
            background: GRAY_LIGHT,
            color: WHITE,
        },
        '&:focus': {
            background: GRAY_LIGHT,
            color: WHITE,
        },
    },
    btnExport: {
        width: 200,
        padding: '12px 60px',
        fontSize: 15,
    },
    contentBottom: {
        [theme.breakpoints.down('xs')]: {
            height: 175,
            '& > div': {
                display: 'flex',
                flexDirection: 'column-reverse',
            },
        },
        '&.MuiDialogContent-root': {
            paddingLeft: 0,
            overflowY: 'visible',
        },
    },
    infoDateRange: {
        display: 'inline-block',
        marginLeft: 20,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginLeft: 0,
        },
        '& span': {
            color: PRIMARY_DARK,
        },
    },
    labelChannel: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    logoChannel: {
        width: 16,
        height: 16,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundOrigin: 'content-box',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    mainTable: {
        overflow: 'auto',
        background: 'white',
        '&::-webkit-scrollbar': {
            height: '.6em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: GRAY_LIGHT,
            borderRadius: 5,
        },
        '& .MuiList-padding': {
            padding: 0,
        },
    },
    sortButon: {
        marginLeft: -16,
        textAlign: 'left',
        '& .MuiButton-endIcon': {
            marginLeft: 0,
        },
        paddingTop: 0,
    },
    tableHead: {
        '& .MuiButton-label': {
            color: PRIMARY_DARK,
            fontWeight: 600,
        },
        '& .MuiTableCell-head': {
            color: PRIMARY_DARK,
            fontWeight: 600,
        },
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 'auto',
        },
        '& .MuiTableCell-sizeSmall': {
            padding: '10px 24px 6px 16px',
        },
    },
    tableRow: {
        '&.gray': {
            backgroundColor: TABLE_GRAY,
        },
        '& .MuiTableCell-body': {
            color: PRIMARY_DARK,
            paddingTop: 15,
            paddingBottom: 10,
            fontSize: 14,
        },
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 'auto',
        },
        '&.noBorderBottom': {
            '& .MuiTableCell-root': {
                borderBottom: 0,
            },
        },
    },
    massAction: {
        color: PRIMARY_DARK,
        fontSize: 14,
        fontWeight: 600,
        paddingLeft: 30,
        paddingBottom: 10,
        paddingTop: 10,
        background: 'white',
        '& .MuiGrid-item': {
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiGrid-spacing-xs-3 > .MuiGrid-item': {
            paddingBottom: 6,
        },
    },
    massActionButton: {
        background: 'white',
        color: PRIMARY_DARK,
        fontSize: 11,
        border: '1px solid',
        borderColor: GRAY_LIGHT,
        boxShadow: 'none',
        padding: '4px 10px',
        marginRight: 10,
        '&:hover': {
            borderColor: GRAY_LIGHT,
            backgroundColor: 'white',
            boxShadow: 'none',
        },
    },
    dividerLine: {
        backgroundColor: TABLE_GRAY,
        margin: 10,
    },
    dividerLineVertical: {
        height: 16,
    },
    printLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    buttonPrint: {
        padding: '5px 10px',
        fontSize: 12,
        boxShadow: 'none',
        border: '1px transparent solid',
        color: PRIMARY_DARK,
        fontWeight: 600,
        backgroundColor: TABLE_GRAY,
        '& img': {
            width: 17,
        },
        '&:hover': {
            boxShadow: 'none',
            background: TABLE_GRAY,
            border: '1px transparent solid',
            color: PRIMARY_DARK,
        },
    },
    counterPrint: {
        fontSize: 12,
        color: PRIMARY_DARK,
        fontWeight: 400,
    },
    centering: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default useStyles;
