import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY } from '@theme_color';
import { FONT_DEFAULT } from '@theme_typography';

const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 8,
        padding: 30,
        fontFamily: FONT_DEFAULT,
        position: 'relative',
    },
    title: {
        color: PRIMARY,
        fontWeight: 'bold',
        fontSize: 24,
        padding: 0,
        paddingBottom: 20,
    },
    content: {
        padding: 0,
        paddingBottom: 20,
    },
    contentText: {
        color: 'black',
        fontSize: 15,
    },
    closeButton: {
        position: 'absolute',
        padding: '0',
        top: 10,
        right: 10,
    },
}));

export default useStyles;
