/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@common_tooltipseller';

import ListCard from '@sellermodules/order/pages/list/components/ListCard';
import Confirmation from '@sellermodules/order/pages/list/components/Confirmation';
import ProgressDialog from '@sellermodules/order/pages/list/components/ProgressDialog';
import useStyles from '@sellermodules/order/pages/list/components/style';

const OrderListContent = (props) => {
    const { data, t, dataProvider, dataLocation, dataTabs, setChecked, setOpenConfirmation, isMultichannel } = props;
    const router = useRouter();
    const classes = useStyles();
    const { status } = router.query;

    const orderList = (data && data.getSellerOrders && data.getSellerOrders.items) || [];
    const orderTotal = (data && data.getSellerOrders && data.getSellerOrders.total_count) || 0;

    const orderSyncFailedList = data?.getSellerOrderSyncStatusList?.items || [];
    const orderSyncFailedTotal = data?.getSellerOrderSyncStatusList?.total_count || 0;

    const columns = [
        { field: 'id', hidden: true },
        { field: 'order_date', sortable: true, hidden: true, initialSort: 'DESC' },
        { field: 'picture' },
        { field: 'location', headerName: t('sellerorder:Order_Location') },
        { field: 'address', headerName: t('sellerorder:Address') },
        { field: 'total', headerName: t('sellerorder:Order_Total') },
        { field: 'expedition', headerName: t('sellerorder:Expedition') },
        { field: 'receipt', headerName: t('sellerorder:Receipt_Number') },
    ];

    const rows = orderList.map((order) => ({
        ...order,
        id: order.entity_id,
        header: () => (
            <div className={classes.head}>
                <div className={classes.flexStatus}>
                    {status === 'order_shipped' ? order.tracks?.status?.label || order.status.label : order.status.label}
                    {!!order.cancel_reason
                    && (
                        <Tooltip
                            title={(
                                <div>
                                    <b>{`${t('sellerorder:Cancel_Reason')}: `}</b>
                                    {order.cancel_reason}
                                </div>
                            )}
                            placement="bottom"
                            arrow
                        />
                    )}
                </div>
                {order.is_indent === 1
                && (
                    <>
                        <div className={clsx(classes.divider, 'slash', 'hidden-mobile')} />
                        <div>{t('sellerorder:Backorder')}</div>
                    </>
                )}
                <div className={clsx(classes.divider, 'slash', 'hidden-mobile')} />
                <span className={clsx(classes.divider, 'primary')}>{order.order_number}</span>
                <div className={clsx(classes.divider, 'slash', 'hidden-mobile')} />
                <div className={clsx(classes.divider, 'slash')}>
                    <PersonOutlineIcon />
                    <div className={clsx(classes.divider, 'space')} />
                    {order.customer.name}
                </div>
                <div className={clsx(classes.divider, 'slash')}>
                    <ScheduleIcon />
                    <div className={clsx(classes.divider, 'space')} />
                    {order.order_date}
                </div>
                <div className={clsx(classes.divider)}>
                    <Tooltip
                        title={order?.channel?.name}
                        placement="bottom"
                        arrow
                    >
                        <div
                            className={classes.channelLogo}
                            style={{
                                backgroundImage: `url(${order?.marketplace_platform?.platform_logo || order?.channel?.logo || '/assets/img/placeholder_image.webp'})`,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        ),
        picture: () => {
            const itemPreview = order.item_preview.items;
            if (itemPreview.item_bundle) {
                return (
                    <Grid container spacing={2} className={classes.imgContainer} alignItems="center">
                        <Grid item xs={12} md="auto">
                            <div className={classes.flex}>
                                {itemPreview.item_bundle.map((item, idx) => (
                                    <div className={classes.flex}>
                                        <div
                                            className={classes.imgGrid}
                                            style={{ backgroundImage: `url(${item.image || '/assets/img/placeholder_image.webp'})` }}
                                            alt="ord-img"
                                        />

                                        <div className={classes.plus}>
                                            {(idx !== (itemPreview.item_bundle?.length - 1)) && '+'}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {itemPreview.name}
                        </Grid>
                        <Grid item xs={12}>
                            {`${itemPreview.qty} x ${itemPreview.price}`}
                        </Grid>
                    </Grid>
                );
            }
            return (
                <Grid container spacing={2} className={classes.imgContainer}>
                    <Grid item xs={12} md={4}>
                        <div
                            className={classes.img}
                            style={{ backgroundImage: `url(${itemPreview.image || '/assets/img/placeholder_image.webp'})` }}
                            alt="ord-img"
                        />
                    </Grid>
                    <Grid item xs={12} md={8} container direction="column" spacing={2}>
                        <Grid item xs>
                            {itemPreview.name}
                        </Grid>
                        <Grid item>
                            {`${itemPreview.qty} x ${itemPreview.price}`}
                        </Grid>
                    </Grid>
                </Grid>
            );
        },
        address: () => (
            <div>
                {order.customer.name}
                <br />
                {order.shipping_address.region}
            </div>
        ),
        total: order.grand_total,
        expedition: `${order.expedition.provider || ''}${order.expedition.service ? ` - ${order.expedition.service}` : ''}`,
        receipt: order.tracks?.track_number,
        footer: order.item_preview.qty_more >= 1 ? `+ ${order.item_preview.qty_more} Other Products` : '',
        location: order.location.loc_name,
    }));

    const actions = [
        {
            label: t('sellerorder:Print_Label'),
            onClick: async (checkedRows) => {
                if (isMultichannel) {
                    setOpenConfirmation('multiple');
                } else {
                    const checkedIds = checkedRows.map((check) => check.id);
                    window.open(`/seller/order/orderlist/printlabel/${checkedIds.join('/')}`);
                }
            },
            showMessage: false,
            code: 'label',
        },
        {
            label: t('sellerorder:Print_Invoice'),
            onClick: (checkedRows) => {
                const checkedIds = checkedRows.map((check) => check.id);
                window.open(`/seller/order/orderlist/printinvoice/${checkedIds.join('/')}`);
            },
            showMessage: false,
            code: 'invoice',
        },
    ];

    const columnsSyncFailed = [
        { field: 'id', hidden: true },
        { field: 'order_number', sortable: true, headerName: t('sellerorder:Order_Number') },
        { field: 'order_date', sortable: true, headerName: t('sellerorder:Order_Date'), initialSort: 'DESC' },
        { field: 'status', headerName: t('sellerorder:Order_Status') },
        { field: 'channel', headerName: t('sellerorder:Store') },
    ];

    const rowsSyncFailed = orderSyncFailedList?.map((order) => ({
        ...order,
        id: order.entity_id,
        status: order.status?.label || '-',
        channel: (
            <div className={classes.channelContainer}>
                <div
                    className={classes.channelIcon}
                    style={{ backgroundImage: `url(${order.channel?.logo || '/assets/img/placeholder_image.jpg'})` }}
                />
                {order.channel?.name || '-'}
            </div>
        ),
    }));

    return (
        <>
            <ListCard
                {...props}
                header={t('sellerorder:Order_List')}
                columns={columns}
                rows={rows}
                actions={actions}
                count={orderTotal}
                columnsSyncFailed={columnsSyncFailed}
                rowsSyncFailed={rowsSyncFailed}
                countSyncFailed={orderSyncFailedTotal}
                showCheckbox
                searchPlaceholder={t('common:Search_for_buyer’s_name_product_receipt_number_or_invoice')}
                useTabs
                dataTab={dataTabs}
                handleClickRow={(row) => router.push({
                    pathname: '/seller/order/orderlist/detail/[id]',
                    query: { id: row.id },
                })}
                t={t}
                dataProvider={dataProvider}
                dataLocation={dataLocation}
                handleChecked={setChecked}
            />
            <Confirmation {...props} />
            <ProgressDialog {...props} />
        </>
    );
};

export default OrderListContent;
