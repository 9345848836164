import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@common_button';
import propTypes from 'prop-types';
import { useTranslation } from '@i18n';
import useStyles from '@common_confirmdialog/style';

const ConfirmationDialog = (props) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const {
        open = false,
        onConfirm,
        onCancel,
        cancelButtonLabel = t('common:Cancel'),
        showCancelButton = true,
        confirmButtonLabel = t('common:OK'),
        showConfirmButton = true,
        onTable = false,
        title,
        message,
        records,
        recordName = t('common:records'),
        maxWidth = 'xs',
        hideAction = false,
        useCloseIcon = false,
    } = props;
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth
            maxWidth={maxWidth}
            classes={{ paper: classes.dialog }}
        >
            {useCloseIcon
                && (
                    <IconButton classes={{ root: classes.closeButton }} onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                )}
            <DialogTitle disableTypography classes={{ root: classes.title }}>{title}</DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
                <DialogContentText classes={{ root: classes.contentText }}>
                    {message}
                    {records ? (
                        <div>
                            {' '}
                            {`(${records} ${recordName})`}
                        </div>
                    ) : null}
                </DialogContentText>
            </DialogContent>
            {!hideAction
                && (
                    <DialogActions>
                        {
                            onTable && records === 0 ? <Button onClick={onCancel} color="primary">{t('common:OK')}</Button>
                                : (
                                    <>
                                        <Button
                                            className={!showCancelButton && 'hide'}
                                            onClick={onCancel}
                                            buttonType="outlined-rounded"
                                            color="primary"
                                            autoFocus
                                        >
                                            {cancelButtonLabel}
                                        </Button>
                                        <Button
                                            className={!showConfirmButton && 'hide'}
                                            onClick={onConfirm}
                                            color="primary"
                                            buttonType="primary-rounded"
                                        >
                                            {confirmButtonLabel}
                                        </Button>
                                    </>
                                )
                        }
                    </DialogActions>
                )}
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    open: propTypes.bool.isRequired,
    onConfirm: propTypes.func.isRequired,
    onCancel: propTypes.func.isRequired,
    onTable: propTypes.bool,
    title: propTypes.string,
    message: propTypes.string,
};

ConfirmationDialog.defaultProps = {
    title: 'Confirmation',
    message: 'Are you sure?',
    onTable: false,
};

export default ConfirmationDialog;
