import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from '@sellermodules/order/services/graphql/schema';

const context = {
    context: { request: 'internal' },
};

const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

export const getSellerOrders = (variables) => useLazyQuery(Schema.getSellerOrders, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const getSellerOrder = (variables) => useQuery(Schema.getSellerOrder, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const printSellerOrderLabel = (variables) => useQuery(Schema.printSellerOrderLabel, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const printSellerChannelOrderLabel = (options) => useLazyQuery(Schema.printSellerChannelOrderLabel, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const retryPrintSellerChannelOrderLabel = (options) => useLazyQuery(Schema.retryPrintSellerChannelOrderLabel, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const printSellerInvoice = (variables) => useQuery(Schema.printSellerInvoice, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const sellerCanceled = (variables) => useMutation(Schema.sellerCanceled, {
    variables,
    ...context,
});

export const sellerConfirm = (variables) => useMutation(Schema.sellerConfirm, {
    variables,
    ...context,
});

export const sellerBookCourier = (variables) => useMutation(Schema.sellerBookCourier, {
    variables,
    ...context,
});

export const sellerReadyforShip = (variables) => useMutation(Schema.sellerReadyforShip, {
    variables,
    ...context,
});

export const sellerOrderDelivered = (variables) => useMutation(Schema.sellerOrderDelivered, {
    variables,
    ...context,
});

export const getGenerateAwbMethod = (variables) => useQuery(Schema.getGenerateAwbMethod, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const sellerOrderInDelivery = (variables) => useMutation(Schema.sellerOrderInDelivery, {
    variables,
    ...context,
});

export const downloadSellerOrderReport = (variables) => useMutation(Schema.downloadSellerOrderReport, {
    variables,
    ...context,
});

export const getSellerStoreOptions = (variables) => useQuery(Schema.getSellerStoreOptions, {
    variables, ...context, ...fetchPolicy,
});

export const sellerCancelDelivery = (variables) => useMutation(Schema.sellerCancelDelivery, {
    variables,
    ...context,
});

export const getPickupTimeslots = (options) => useLazyQuery(Schema.getPickupTimeslots, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getSellerOrdersCount = (variables) => useQuery(Schema.getSellerOrdersCount, {
    variables, ...context, ...fetchPolicy,
});

export const getSellerOrderStatus = (variables) => useQuery(Schema.getSellerOrderStatus, {
    variables, ...context, ...fetchPolicy,
});

export const getSellerOrderMeta = (variables) => useQuery(Schema.getSellerOrderMeta, {
    variables, ...context, ...fetchPolicy,
});

export const getCancelReasonsByChannel = (variables) => useLazyQuery(Schema.getCancelReasonsByChannel, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const csStores = (options) => useQuery(Schema.csStores, {
    ...options,
    ...context,
    ...fetchPolicy,
});

export const getSellerOrderSyncStatusList = (variables) => useLazyQuery(Schema.getSellerOrderSyncStatusList, {
    variables, ...context, ...fetchPolicy,
});

export const getSellerOrderSyncStatusListCount = (variables) => useQuery(Schema.getSellerOrderSyncStatusListCount, {
    variables, ...context, ...fetchPolicy,
});

export const retrySellerOrderSync = (variables) => useMutation(Schema.retrySellerOrderSync, {
    variables, ...context,
});

export const getActivity = (options) => useLazyQuery(Schema.getActivity, {
    ...context, ...options, ...fetchPolicy,
});

export const getExportHistory = (variables) => useLazyQuery(Schema.getExportHistory, {
    ...context, variables, ...fetchPolicy,
});

export const getSellerChannelList = (options) => useLazyQuery(Schema.getSellerChannelList, {
    ...context, ...options, ...fetchPolicy,
});

export const getMarketplacePlatforms = (variables) => useQuery(Schema.getMarketplacePlatforms, {
    variables, ...context, ...fetchPolicy,
});

export default {
    getSellerOrders,
    getSellerOrder,
    printSellerOrderLabel,
    printSellerChannelOrderLabel,
    retryPrintSellerChannelOrderLabel,
    printSellerInvoice,
    sellerCanceled,
    sellerConfirm,
    sellerBookCourier,
    sellerReadyforShip,
    sellerOrderDelivered,
    getGenerateAwbMethod,
    sellerOrderInDelivery,
    downloadSellerOrderReport,
    getSellerStoreOptions,
    sellerCancelDelivery,
    getPickupTimeslots,
    getSellerOrdersCount,
    getSellerOrderStatus,
    getSellerOrderMeta,
    getCancelReasonsByChannel,
    csStores,
    getSellerOrderSyncStatusList,
    getSellerOrderSyncStatusListCount,
    retrySellerOrderSync,
    getActivity,
    getExportHistory,
    getSellerChannelList,
    getMarketplacePlatforms,
};
