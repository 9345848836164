import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    PRIMARY_DARK, TABLE_GRAY, GRAY_LIGHT,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        backgroundColor: TABLE_GRAY,
        padding: '20px 30px 24px 30px',
    },
    gridFilters: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: 20,
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(12fr)',
        },
    },
    gridColumnInput: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    filterLabel: {
        color: PRIMARY_DARK,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        marginBottom: 10,
    },
    inputFlex: {
        display: 'grid',
        gridTemplateColumns: '90px auto',
        gap: 10,
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'unset',
        },
    },
    inputLabel: {
        color: PRIMARY_DARK,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
    },
    textInput: {
        width: '100%',
        background: 'white',
        borderRadius: 8,
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .MuiInput-root': {
            fontSize: 14,
            minHeight: 42,
        },
        '& .MuiInputBase-input': {
            color: PRIMARY_DARK,
            padding: '0px 20px',
            maxHeight: 42,
        },
        '& .MuiInputBase-input::placeholder': {
            color: GRAY_LIGHT,
            opacity: 1,
        },
        '& .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
    },
    textInputAutoComplete: {
        width: '100%',
        background: 'white',
        borderRadius: 8,
        '& .MuiInput-underline:before': {
            display: 'none',
        },
        '& .MuiInput-root': {
            fontSize: 14,
            padding: '0px 20px',
            minHeight: 42,
        },
        '& .MuiInputBase-input': {
            color: PRIMARY_DARK,
            padding: '0px 20px',
        },
        '& .MuiInputBase-input::placeholder': {
            color: GRAY_LIGHT,
            opacity: 1,
        },

        '& .MuiChip-root': {
            backgroundColor: 'transparent',
            border: `1px solid ${GRAY_LIGHT}`,
            borderRadius: 8,
        },
        '& .MuiChip-deleteIcon': {
            fill: PRIMARY_DARK,
        },
        '& .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
        '& .MuiAutocomplete-endAdornment': {
            '& .MuiSvgIcon-root': {
                fill: `${PRIMARY_DARK} !important`,
            },
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child': {
            padding: 0,
        },
        '& .MuiChip-labelSmall': {
            maxWidth: 75,
        },
        '&.hasValue': {
            '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input': {
                padding: 0,
            },
        },
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
            paddingRight: 0,
        },
        '& .MuiAutocomplete-clearIndicator': {
            padding: 0,
        },
        '& .MuiAutocomplete-popupIndicator': {
            padding: 0,
            paddingRight: 10,
        },
    },
    rootAutocomplete: {
        padding: 0,
        width: '100%',
    },
    listboxAutocomplete: {
        width: '100%',
        '&::-webkit-scrollbar': {
            width: '.4em',
            height: '.4em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: GRAY_LIGHT,
            borderRadius: 5,
        },
    },
    paperAutocomplete: {
        display: 'flex',
        padding: '0',
        alignItems: 'center',
        gap: '10px',
        borderRadius: 8,
        border: `1px solid ${GRAY_LIGHT}`,
    },
    popperAutocomplete: {
        width: '250px !important',
    },
    optionAutocomplete: {
        color: PRIMARY_DARK,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
    buttonContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'end',
    },
    button: {
        fontWeight: 700,
        borderRadius: 8,
        fontSize: 13,
        '&:hover': {
            backgroundColor: PRIMARY_DARK,
        },
    },
    buttonText: {
        fontWeight: 700,
        color: PRIMARY_DARK,
        fontSize: 14,
        '&:hover': {
            color: PRIMARY_DARK,
        },
    },
}));

export default useStyles;
