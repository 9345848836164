import makeStyles from '@material-ui/core/styles/makeStyles';
import { PRIMARY_DARK, GRAY_LIGHT } from '@theme_color';

const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: 'white',
        color: PRIMARY_DARK,
        width: 'fit-content',
        maxWidth: 250,
        boxShadow: '0px 3px 10px 0px rgba(213, 209, 218, 0.50), 0px 0px 6px 0px rgba(213, 209, 218, 0.30)',
        border: `1px solid ${GRAY_LIGHT}`,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: '8px 10px',
    },
    tooltipArrow: {
        color: GRAY_LIGHT,
    },
    tooltipImg: {
        cursor: 'pointer',
    },
}));

export default useStyles;
