/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, createRef, useEffect } from 'react';
import clsx from 'clsx';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import TimePickerPanel from 'rc-time-picker/lib/Panel';

import moment from 'moment';
import 'moment/locale/en-gb';

import TextField from '@common_textfield';
import useStyles from '@common_calendar/style';

const now = moment();
now.locale('en-gb').utcOffset(0);

const Page = (props) => {
    const {
        format = 'YYYY-MM-DD HH:mm:ss',
        defaultValue = moment(),
        showToday = false,
        useTime = false,
        showClear = false,
        date = '',
        setDate = () => { },
        useTemp = false,
        calendarClassName,
        user = 'seller',
        textFieldProps = {},
        showDateInput = true,
        focusablePanel = false,
        calendarStyle = {},
        containerClassName = undefined,
        disabled = false,
        disablePast = false,
        renderFooter = () => {},
    } = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [dateTemp, setDateTemp] = useState(date);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const calendarContainerRef = createRef();

    const onChange = (v) => {
        const set = useTemp ? setDateTemp : setDate;
        set(v ? moment(v).format(format) : '');
    };

    const onOpenChange = (v) => {
        if (!disabled) setOpen(v);
    };

    const onMouseDown = () => {
        if (!disabled) setIsMouseDown(true);
    };

    const onFocus = () => {
        if (!open && isMouseDown) {
            // focus from a "click" event, let the picker trigger automatically open the calendar
            if (!disabled) setIsMouseDown(false);
        } else {
            // focus not caused by "click" (such as programmatic or via keyboard)
            // eslint-disable-next-line no-lonely-if
            if (!disabled) setOpen(true);
        }
    };

    const getCalendarContainer = () => calendarContainerRef.current;
    const timePickerElement = <TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} />;

    const disabledDateCalendar = (current) => {
        if (current.date() === moment().date() && current.month() === moment().month() && current.year() === moment().year()) {
            return false;
        }
        return current < moment();
    };

    useEffect(() => {
        if (useTemp) {
            setDateTemp(date);
        }
    }, [open]);

    const calendar = (
        <Calendar
            locale={enUS}
            className={clsx(user === 'admin' ? classes.calendarAdmin : classes.calendar, calendarClassName)}
            format={format}
            timePicker={useTime && timePickerElement}
            defaultValue={defaultValue}
            showDateInput={showDateInput}
            focusablePanel={focusablePanel}
            showToday={showToday}
            showClear={showClear}
            style={calendarStyle}
            disabled={disabled}
            disabledDate={disablePast ? disabledDateCalendar : null}
            disabledTime={!useTime}
            showOk={false}
            renderFooter={() => renderFooter(onOpenChange, dateTemp)}
        />
    );

    const dateUsed = useTemp ? dateTemp : date;
    return (
        <div className={containerClassName}>
            <DatePicker
                animation="slide-up"
                calendar={calendar}
                value={dateUsed ? moment(dateUsed, format) : ''}
                onChange={onChange}
                getCalendarContainer={getCalendarContainer}
                onOpenChange={onOpenChange}
                open={open}
                disabled={disabled}
            >
                {
                    ({ value }) => (
                        <span tabIndex="0" onMouseDown={onMouseDown} onFocus={onFocus}>
                            <TextField
                                className={user === 'admin' ? classes.textInputAdmin : classes.textInput}
                                readOnly
                                tabIndex="-1"
                                value={(value && moment(value)?.format(format)) || ''}
                                {...(user === 'admin' && {
                                    fullWidth: true,
                                    variant: 'outlined',
                                    InputProps: {
                                        className: classes.fieldInput,
                                    },
                                })}
                                disabled={disabled}
                                {...textFieldProps}
                            />
                            <div ref={calendarContainerRef} />
                        </span>
                    )
                }
            </DatePicker>
        </div>
    );
};

export default Page;
