/* eslint-disable no-nested-ternary */
import { useRouter } from 'next/router';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';

import Tabs from '@common_tabsseller';
import Progress from '@common_progresspercent';
import Button from '@common_button';

import ExportContent from '@sellermodules/order/pages/list/components/ListCard/components/ExportDialog/ExportContent';
import HIstoryContent from '@sellermodules/order/pages/list/components/ListCard/components/ExportDialog/HIstoryContent';
import useStyles from '@sellermodules/order/pages/list/components/ListCard/style';

const ExportOrder = (props) => {
    const {
        t, openExport, setOpenExport, activityState, activityCalled, setActivityCalled,
    } = props;
    const router = useRouter();
    const classes = useStyles();

    const [tab, setTab] = React.useState('export');
    const dataTabs = [
        { label: t('sellerorder:Export'), value: 'export' },
        { label: t('sellerorder:History'), value: 'history' },
    ];
    const Component = {
        export: <ExportContent {...props} />,
        history: <HIstoryContent {...props} />,
    };

    const handleClose = () => setOpenExport(false);

    return (
        <Dialog
            open={openExport}
            onClose={activityState?.run_status === 'running' || (activityState?.run_status === 'finished' && activityCalled)
                ? null : handleClose}
            classes={{ paper: classes.dialogContainer }}
            maxWidth="sm"
        >
            <DialogTitle className={classes.dialogTitleContainer}>
                {t('sellerorder:Export_Order')}
                {!(activityState?.run_status === 'running' || (activityState?.run_status === 'finished' && activityCalled))
                && (
                    <IconButton className={classes.closeButtonDialog} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
            {activityState?.run_status === 'running' || (activityState?.run_status === 'finished' && activityCalled)
                ? (
                    <div className={classes.dialogContentDiv}>
                        <div className={classes.centering}>
                            <Progress
                                value={Math.round(activityState?.data_processed || 0)}
                                title="Progress"
                                variant={activityState?.data_processed ? 'determinate' : 'indeterminate'}
                            />
                        </div>
                        {activityState?.run_status === 'finished'
                        && (
                            <>
                                {!!activityState.attachment
                                && (
                                    <DialogContent>
                                        <div className={classes.centering}>
                                            <Button user="seller" buttonType="buttonText" onClick={() => router.push(activityState.attachment)}>
                                                {t('sellerorder:Download')}
                                            </Button>
                                        </div>
                                    </DialogContent>
                                )}
                                <DialogContent>
                                    <div className={classes.centering}>
                                        <Button
                                            user="seller"
                                            onClick={async () => {
                                                await handleClose();
                                                setTimeout(() => setActivityCalled(false), 500);
                                            }}
                                        >
                                            {t('sellerorder:Close')}
                                        </Button>
                                    </div>
                                </DialogContent>
                            </>
                        )}
                    </div>
                )
                : (
                    <>
                        <Tabs data={dataTabs} onChange={(_, v) => setTab(v)} value={tab} allItems={false} />
                        <div className={classes.dialogContentDiv}>
                            {Component[tab]}
                        </div>
                    </>
                )}

        </Dialog>
    );
};

export default ExportOrder;
