import _app from '@modules/theme/pages/_app';

// /**
//  * Import global css
//  * */
import '@styles/index.css';
import '@styles/mediaquery.css';
import '@styles/flexboxgrid.min.css';
import '@styles/checkboxtree.css';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';

export default _app;
